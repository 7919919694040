import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class PopupService {
  private apiPrefix = '/admin/popup';

  constructor(
    private api: ApiService
  ) {
  }

  createPopup(data: object) {
    return this.api.post(`${this.apiPrefix}`, data);
  }

  updatePopup(id: number, data: object) {
    return this.api.patch(`${this.apiPrefix}/${id}`, data);
  }

  getPopups(qs = '') {
    return this.api.get(`${this.apiPrefix}${qs}`);
  }

  deletePopup(id: number) {
    return this.api.delete(`${this.apiPrefix}/${id}`);
  }

  changeActive(id: number, data: object) {
    return this.api.patch(`${this.apiPrefix}/${id}/publish`, data);
  }

  getAttachments(id: number) {
    return this.api.get(`${this.apiPrefix}/${id}/attachment`);
  }

  createAttachment(id: number, data: object) {
    return this.api.post(`${this.apiPrefix}/${id}/attachment`, data);
  }

  createImage(id: number, data: object){
    return this.api.post(`${this.apiPrefix}/${id}/attachment/image`, data);
  }

  saveImage(id: number, requestId: string){
    return this.api.patch(`${this.apiPrefix}/${id}/attachment/image/${requestId}`, {});
  }

  deleteAttachment(popupId: number, attachmentId: number){
    return this.api.delete(`${this.apiPrefix}/${popupId}/attachment/${attachmentId}`);
  }
}
