<div class="modal-header">
  <h5 class="modal-title mt-0">
    {{'content_types.' + type|translate}} -
    {{'labels.publish_settings'|translate}}
  </h5>
  <button type="button" class="btn-close" (click)="modal.hide()">
    <span aria-hidden="true"></span>
  </button>
</div>

<div class="modal-body">

  <form *ngIf="fg" class="row" [formGroup]="fg" (ngSubmit)="onSubmit()">

    <div class="row">
      <div class="col-6 mt-2 text-center" *ngIf="withNotifyOption">
        <label class="mb-0">{{'labels.send_notification'|translate}}</label>
        <ui-switch
          color="#556ee6" [checked]="notify" (change)="onNotifyChange($event)" uncheckedLabel="" checkedLabel=""
          size="small"
          class="ms-1 me-1"></ui-switch>
      </div>

      <div class="mt-2 text-center" [class.col-12]="!withNotifyOption" [class.col-6]="withNotifyOption">
        <label class="mb-0">{{'labels.publish_now'|translate}}</label>
        <ui-switch
          color="#556ee6" [checked]="publishNow" (change)="onPublishNowChange($event)" uncheckedLabel="" checkedLabel=""
          size="small"
          class="ms-1 me-1"></ui-switch>
      </div>
    </div>

    <div class="row mt-2">
      <ng-container *ngIf="withNotifyOption && withCustomizeNotification">
        <div class="row">
          <div class="col-md-6 mt-2 text-center customize-notify-switch">
            <label class="mb-0">{{'labels.customize_notification'|translate}}</label>
            <ui-switch
              color="#556ee6" [checked]="customizeNotification" (change)="onCustomizeNotifyChange($event)" uncheckedLabel="" checkedLabel=""
              size="small"
              class="ms-1 me-1"></ui-switch>
          </div>
          <div class="col-md-6 mt-2 text-center customize-notify-switch">
          </div>
          <div class="mt-2 customize-notify-details" [class.d-none]="!customizeNotification">
            <div class="col-md-12 mt-2 text-center">
              <label for="notificationTitle" class="form-label">{{'labels.notification_title'|translate}}</label>
              <input
                id="notificationTitle" class="form-control"
                autocomplete="off" type="text" maxlength="65"
                formControlName="notificationTitle"
                [placeholder]="'labels.notification_title'|translate">
            </div>
            <div class="col-md-12 mt-2 text-center">
              <label
                for="notificationDescription"
                class="form-label">{{'labels.notification_description'|translate}}
              </label>
              <textarea
                id="notificationDescription" class="form-control"
                autocomplete="off" type="text" maxlength="240" rows="5"
                formControlName="notificationDescription"
                [placeholder]="'labels.notification_description'|translate"></textarea>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="row">
      <div class="col-12 mt-2 mb-2">
      </div>
    </div>

    <div class="row" *ngIf="!publishNow">
      <div class="col-md-6 mt-2 text-center">
        <label
          for="publishAtDate"
          class="form-label">
          {{'labels.be_published_at'|translate}}
        </label>

        <app-date-picker id="publishAtDate" (ngModelChange)="onDateChange($event)"
                         formControlName="publishDate" [minDate]="minDate" [maxDate]="maxDate"></app-date-picker>

        <div *ngIf="isSubmitted && hasTimeErr" class="invalid-feedback">
          <span>{{'validation.enter_valid_value'|translate}}</span>
        </div>
      </div>

      <div class="col-md-6 mt-2 text-center">
        <timepicker [showMeridian]="false" (ngModelChange)="onDateChange($event)"
                    formControlName="publishTime"></timepicker>
      </div>
    </div>

    <div class="row m-2 mt-4 p-0">
      <p class="description">{{'modals.content_publish.order_description'|translate}}</p>
    </div>

    <div class="row buttons-row">
      <div class="" [class.col-md-6]="isPublished" [class.col-md-12]="!isPublished">
        <button [disabled]="isLoading" class="btn btn-primary w-100" type="submit">
          <i *ngIf="isLoading" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          <span *ngIf="!isPublished">{{'buttons.publish'|translate}}</span>
          <span *ngIf="isPublished">{{'buttons.change'|translate}}</span>
        </button>
      </div>
      <div class="col-md-6 text-center" *ngIf="isPublished">
        <button *ngIf="isPublished" (click)="unPublish()" class="btn btn-danger w-100 me-2" type="button">
          <i class="bx bx-trash font-size-16 align-middle me-2"></i>
          {{'buttons.unpublish'|translate}}
        </button>
      </div>
    </div>
  </form>

</div>
