import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class PaymentPlanService {
  private apiPrefix = '/admin/payment-plan';

  constructor(
    private api: ApiService
  ) {
  }

  getPlanTypes() {
    return this.api.get(`${this.apiPrefix}`);
  }

  setPeriodPlanTypeActiveState(planTypeId: number, periodId: number, isActive: boolean) {
    return this.api.patch(`${this.apiPrefix}/${planTypeId}/state`, {
      periodId, isActive
    })
  }

  getPeriodPlanTypes(periodId: number){
    return this.api.get(`${this.apiPrefix}/period/${periodId}`);
  }
}
