import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";

@Injectable()
export class ExamPeriodService {
  private readonly apiPrefix = '/admin/exam-periods';


  constructor(
    private api: ApiService,
  ) {
  }

  getExamPeriods(filter: string): Observable<any> {
    return this.api.get(`${this.apiPrefix}${filter}`);
  }

  activatePeriod(periodId: number): Observable<any> {
    return this.api.patch(`${this.apiPrefix}/${periodId}/activate`, {});
  }

  createPeriod(data: object): Observable<any> {
    return this.api.post(`${this.apiPrefix}`, data);
  }

  updatePeriod(periodId: number, data: object): Observable<any> {
    return this.api.patch(`${this.apiPrefix}/${periodId}`, data);
  }

  setListedStatus(periodId: number, data: object) {
    return this.api.patch(`${this.apiPrefix}/${periodId}/is-listed`, data);
  }
}
