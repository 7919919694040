import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from "ngx-bootstrap/modal";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AlertModule } from "ngx-bootstrap/alert";
import { ContentPublishModalComponent } from "./content-publish-modal/content-publish-modal.component";
import { UIModule } from "../ui/ui.module";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { UiSwitchModule } from "ngx-ui-switch";


@NgModule({
  declarations: [
    ContentPublishModalComponent
  ],
  imports: [
    CommonModule,
    ModalModule,
    TranslateModule,
    ReactiveFormsModule,
    AlertModule,
    UIModule,
    TimepickerModule,
    UiSwitchModule,
    FormsModule,
  ],
  exports: [
    ContentPublishModalComponent,
  ]
})
export class ContentModalsModule {
}
