import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class PlanPriceService {
  private apiPrefix = '/admin/plan-prices';

  constructor(
    private api: ApiService,
  ) {
  }

  createPrice(data: object) {
    return this.api.post(`${this.apiPrefix}`, data);
  }

  getPeriodPlanTypePrices(typeKey: string, periodId: number, qs = '') {
    return this.api.get(`${this.apiPrefix}/${typeKey}/period/${periodId}/${qs}`)
  }

  activate(id: number) {
    return this.api.patch(`${this.apiPrefix}/${id}/active`, {});
  }
}
