import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { finalize, Observable, Subject, takeUntil } from "rxjs";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as moment from "moment";
import { ICampaign } from "../../../core/models/campaign/campaign.model";
import { CampaignService } from "../../../core/services/campaign/campaign.service";
import { ContentType } from "../../../core/enums/content-type.enum";
import { IBlogArticle } from "../../../core/models/blog/blog-article.model";
import { ICommunityPost } from "../../../core/models/community/community-post.model";
import { IVideo } from "../../../core/models/video/video.model";
import { BlogArticleService } from "../../../core/services/blog/blog-article.service";
import { CommunityPostService } from "../../../core/services/community/community-post.service";
import { VideoService } from "../../../core/services/video/video.service";
import { IContentPublishOptions } from "../../../core/interfaces/content-publish-options.interface";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { IPlaylist } from "../../../core/models/video/playlist.model";
import { PlaylistService } from "../../../core/services/video/playlist.service";

@Component({
  selector: 'app-content-publish-modal',
  templateUrl: './content-publish-modal.component.html',
  styleUrls: ['./content-publish-modal.component.scss']
})
export class ContentPublishModalComponent implements OnInit, OnDestroy {
  @Input() type: ContentType;
  @Input() entity: ICampaign | IBlogArticle | ICommunityPost | IVideo | IPlaylist;
  @Input() withNotifyOption: boolean = false;
  @Input() withCustomizeNotification: boolean = false;

  @Input() minDate?: Date;
  @Input() maxDate?: Date;

  @Output() submitted = new EventEmitter();

  fg: FormGroup;
  isSubmitted = false;
  isLoading = false;

  publishTime: any;
  publishDate: any;
  publishNow = true;
  notify: boolean = false;
  customizeNotification: boolean = false;

  isPublished: boolean = false;

  hasTimeErr = false;
  destroyed$ = new Subject();

  constructor(
    public modal: BsModalRef,
    private formBuilder: FormBuilder,
    private campaignService: CampaignService,
    private blogService: BlogArticleService,
    private communityPostService: CommunityPostService,
    private videoService: VideoService,
    private playlistService: PlaylistService,
    private translate: TranslateService,
  ) {
  }

  ngOnInit() {
    this.isPublished = !!this.entity.publishedAt;
    this.setupForm();
  }

  setupForm() {
    this.fg = this.formBuilder.group({
      publishDate: [null, []],
      publishTime: [null, []],
      notificationTitle: [null, []],
      notificationDescription: [null, []],
    });

    // Set publish now state
    if (this.isPublished) {
      this.publishNow = false;
    }

    this.fillPublishDate();
  }

  fillPublishDate() {
    if (this.isPublished) {
      this.fg.patchValue({
        publishDate: new Date(this.entity.publishedAt),
        publishTime: new Date(this.entity.publishedAt),
      })
    } else {
      this.fg.patchValue({
        publishDate: null,
        publishTime: null,
      })
    }
  }

  onPublishNowChange(e: any) {
    this.publishNow = e;
    this.hasTimeErr = false;
    if (this.publishNow === true) {
      this.fg.patchValue({
        publishDate: null,
        publishTime: null,
      })
    } else {
      this.fillPublishDate();
    }
  }

  onNotifyChange(e: any) {
    this.notify = e;
    if (e === false)
      this.customizeNotification = false;
  }

  onCustomizeNotifyChange(e: any) {
    this.customizeNotification = e;
    if (e === true) {
      this.setDefaultCustomNotificationContent();
      this.notify = true;
    }
  }

  onDateChange(e) {
    this.setDateErr();
  }

  setDefaultCustomNotificationContent() {
    let notifyTitle = '';
    let notifyDescription = '';
    switch (this.type) {
      case ContentType.COMMUNITY_POST:
        if ((this.entity as ICommunityPost).relatedSurveyId) {
          notifyTitle = this.translate.instant(`custom_notification_content.${this.type}.isRelatedWithSurvey.title`);
          notifyDescription = this.translate.instant(`custom_notification_content.${this.type}.isRelatedWithSurvey.description`);
        } else {
          notifyTitle = this.translate.instant(`custom_notification_content.${this.type}.default.title`);
          notifyDescription = this.translate.instant(`custom_notification_content.${this.type}.default.description`);
        }
        break;
      case ContentType.BLOG:
      case ContentType.CAMPAIGN:
      case ContentType.LIVE_STREAM:
      case ContentType.VIDEO:
        notifyTitle = '';
        notifyDescription = '';
        break;
    }

    this.fg.patchValue({
      notificationTitle: notifyTitle,
      notificationDescription: notifyDescription,
    });
  }

  setDateErr() {
    const formVal = this.fg.value;
    if (!this.publishNow) {
      this.hasTimeErr = ((!formVal.publishDate) || (!formVal.publishTime));

      if (!this.hasTimeErr && this.minDate) {
        const sendAt = new Date(formVal.publishDate);
        sendAt.setHours(formVal.publishTime.getHours(), formVal.publishTime.getMinutes());

        const minDate = moment(this.minDate);

        const diff = moment(sendAt).diff(minDate);
        this.hasTimeErr = diff <= 0;
      }
    }
  }

  onSubmit() {
    this.hasTimeErr = false;
    this.isSubmitted = true;
    this.setDateErr();

    if (this.fg.invalid || this.hasTimeErr) {
      console.log(this.fg.invalid, this.fg.errors);
      return;
    }

    this.isLoading = true;
    const formVal = this.fg.value;

    let publishDate: Date;
    if (!this.publishNow) {
      publishDate = new Date(formVal.publishDate);
      publishDate.setHours(formVal.publishTime.getHours(), formVal.publishTime.getMinutes());
    }

    let extraData = {};
    if (this.customizeNotification) {
      extraData = {
        customizeNotification: true,
        notificationTitle: formVal.notificationTitle,
        notificationDescription: formVal.notificationDescription,
      };
    }

    this.publish(publishDate, extraData);
  }

  async unPublish() {
    const response = await Swal.fire({
      title: this.translate.instant('toast_messages.attention'),
      icon: 'warning',
      html: this.translate.instant('toast_messages.confirm_unpublish'),
      showCloseButton: true,
      showCancelButton: true,
      showDenyButton: false,
      confirmButtonText: this.translate.instant('buttons.yes'),
      cancelButtonText: this.translate.instant('buttons.cancel'),
      denyButtonText: this.translate.instant('buttons.no'),
    });

    if (!response.isConfirmed)
      return;

    let method: Observable<any>;
    switch (this.type) {
      case ContentType.CAMPAIGN:
        method = this.campaignService.unpublish(this.entity.id);
        break;
      case ContentType.BLOG:
        method = this.blogService.unpublish(this.entity.id);
        break;
      case ContentType.VIDEO:
        method = this.videoService.unpublish(this.entity.id);
        break;
      case ContentType.COMMUNITY_POST:
        method = this.communityPostService.unpublish(this.entity.id);
        break;
      case ContentType.PLAYLIST:
        method = this.playlistService.unpublish(this.entity.id);
        break;
      default:
        return;
    }

    method.pipe(takeUntil(this.destroyed$), finalize(() => this.isLoading = false))
      .subscribe(r => {
        this.submitted.emit(r.data);
        this.modal.hide();
      });
  }

  publish(publishDate?: Date, extraData: object = {}) {
    const opts: IContentPublishOptions = {
      notify: !!this.notify,
      publishDate: publishDate,
      ...extraData
    };

    let method: Observable<any>;
    switch (this.type) {
      case ContentType.CAMPAIGN:
        method = this.campaignService.publish(this.entity.id, opts);
        break;
      case ContentType.BLOG:
        method = this.blogService.publish(this.entity.id, opts);
        break;
      case ContentType.VIDEO:
        method = this.videoService.publish(this.entity.id, opts);
        break;
      case ContentType.COMMUNITY_POST:
        method = this.communityPostService.publish(this.entity.id, opts);
        break;
      case ContentType.PLAYLIST:
        method = this.playlistService.publish(this.entity.id, opts);
        break;
      default:
        return;
    }

    method.pipe(takeUntil(this.destroyed$), finalize(() => this.isLoading = false))
      .subscribe(r => {
        this.submitted.emit(r.data);
        this.modal.hide();
      });
  }

  get f() {
    return this.fg.controls;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
