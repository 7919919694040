import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";
import { IContentPublishOptions } from "../../interfaces/content-publish-options.interface";

@Injectable()
export class VideoService {
  private readonly apiPrefix = '/admin/videos';

  constructor(
    private api: ApiService
  ) {
  }

  getVideos(qs: string = '') {
    return this.api.get(`${this.apiPrefix}${qs}`);
  }

  createVideo(data): Observable<any> {
    return this.api.post(this.apiPrefix, data);
  }

  updateVideo(id: number, data): Observable<any> {
    return this.api.patch(`${this.apiPrefix}/${id}`, data);
  }

  deleteVideo(id: number): Observable<any> {
    return this.api.delete(`${this.apiPrefix}/${id}`);
  }

  publish(id: number, opts: IContentPublishOptions) {
    return this.api.patch(`${this.apiPrefix}/${id}/publish`, opts);
  }

  unpublish(id: number) {
    return this.api.patch(`${this.apiPrefix}/${id}/unpublish`, {});
  }

  uploadVideoRequest(id: number, data): Observable<any> {
    return this.api.post(`${this.apiPrefix}/${id}/image`, data);
  }

  saveVideoImage(id: number, requestId: string): Observable<any> {
    return this.api.patch(`${this.apiPrefix}/${id}/image/${requestId}`, {});
  }

  getVideoPlaylists(id: number) {
    return this.api.get(`${this.apiPrefix}/${id}/playlists`);
  }

  updateVideoPlaylists(id: number, data: object) {
    return this.api.put(`${this.apiPrefix}/${id}/playlists`, data);
  }

  getChapters(id: number) {
    return this.api.get(`${this.apiPrefix}/${id}/chapters`);
  }

  updateChapters(id: number, data) {
    return this.api.patch(`${this.apiPrefix}/${id}/chapters`, data);
  }

  createRemoteVideo(body = {}) {
    return this.api.post(`${this.apiPrefix}/remote-video`, {
      method: 'DIRECT',
      unpublish: false, publish: true,
      ...body
    });
  }

  getRemoteVideo(id: string) {
    return this.api.get(`${this.apiPrefix}/remote-video/${id}`);
  }
}
