import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { IContentPublishOptions } from "../../interfaces/content-publish-options.interface";

@Injectable()
export class PlaylistService {
  private readonly apiPrefix = '/admin/playlists';

  constructor(
    private api: ApiService
  ) {
  }

  createPlaylist(data: object) {
    return this.api.post(`${this.apiPrefix}`, data);
  }

  updatePlaylist(id: number, data: object) {
    return this.api.patch(`${this.apiPrefix}/${id}`, data);
  }

  deletePlaylist(id: number) {
    return this.api.delete(`${this.apiPrefix}/${id}`);
  }

  getPlaylists(queryString = '') {
    return this.api.get(`${this.apiPrefix}${queryString}`);
  }

  changeShowInDashboard(id: number, showInDashboard: boolean) {
    return this.api.patch(`${this.apiPrefix}/${id}/show-in-dashboard/${showInDashboard ? 1 : 0}`, {});
  }

  uploadPlaylistImage(id: number, mimeType: string) {
    return this.api.post(`${this.apiPrefix}/${id}/image`, {mimeType});
  }

  savePlaylistImage(id: number, requestId: string) {
    return this.api.patch(`${this.apiPrefix}/${id}/image/${requestId}`, {});
  }

  publish(id: number, opts: IContentPublishOptions) {
    const body = {
      publishDate: opts?.publishDate ?? new Date(),
    };

    return this.api.patch(`${this.apiPrefix}/${id}/publish`, body);
  }

  unpublish(id: number) {
    return this.api.patch(`${this.apiPrefix}/${id}/unpublish`, {});
  }
}
