import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class ModulesService {
  private apiPrefix = '/admin/modules'

  constructor(
    private api: ApiService,
  ) {
  }

  getModuleVisibilitiesForPeriod(periodId: number) {
    return this.api.get(`${this.apiPrefix}/${periodId}/visibility`);
  }

  updatePeriodModulesVisibility(periodId: number, data: any) {
    return this.api.post(`${this.apiPrefix}/${periodId}/visibility`, data);
  }
}
