import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";

@Injectable()
export class ReportService {
  private apiPrefix = '/admin/reports';

  constructor(
    private api: ApiService
  ) {
  }

  getExamScoreReportList(qs = ''): Observable<any> {
    return this.api.get(`${this.apiPrefix}/exam-score${qs}`);
  }

  createExamScoreReport(qs = '', body: object) {
    return this.api.post(`${this.apiPrefix}/exam-score${qs}`, body);
  }

  getUsersReportList(qs = ''): Observable<any> {
    return this.api.get(`${this.apiPrefix}/users${qs}`);
  }

  createUsersReportList(qs = '') {
    return this.api.post(`${this.apiPrefix}/users${qs}`, {});
  }

  getReports(qs = '') {
    return this.api.get(`${this.apiPrefix}${qs}`);
  }
}
